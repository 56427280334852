import { cn } from '@srcTailwindUi/lib/utils';
import type { ViewProps } from './types';
const View = ({
  fullwidth = false,
  color = 'white',
  children
}: ViewProps) => {
  const paddingSpacing = 'pt-24 pr-4 pb-0 pl-4';
  return <div className={cn('absolute h-auto min-h-full w-full text-white', fullwidth ? 'p-0' : paddingSpacing, color)} style={{
    backgroundColor: color
  }} data-sentry-component="View" data-sentry-source-file="view.tsx">
      {children}
    </div>;
};
export { View, ViewProps };