import type { ProgressBarProps } from './types';
import View from './view';
function ProgressBar({
  step,
  totalSteps,
  animate,
  scrollable,
  fullWidth = false,
  scrollRef
}: ProgressBarProps) {
  return <View step={step} totalSteps={totalSteps} animate={animate} scrollable={scrollable} fullWidth={fullWidth} scrollRef={scrollRef} data-sentry-element="View" data-sentry-component="ProgressBar" data-sentry-source-file="index.tsx" />;
}
export { ProgressBar, type ProgressBarProps };