import React from 'react';
import type { TransitionComponentProps } from './types';
import { View } from './view';
const defaultTransition = {
  duration: 0.1,
  type: 'easeInOut'
};
export const FadeTransition = ({
  children,
  transition = defaultTransition
}: TransitionComponentProps) => {
  return <View transition={transition} data-sentry-element="View" data-sentry-component="FadeTransition" data-sentry-source-file="index.tsx">{children}</View>;
};