import type { InstallmentV2 } from '@monorepo/interfaces';
import { TeachingMaterialStatus } from '@monorepo/interfaces/teachingMaterial/status';

export const useTeachingMaterial = (
  installmentsData: InstallmentV2[] | undefined
) => {
  const openInstallments: InstallmentV2[] = [];
  const paidInstallments: InstallmentV2[] = [];

  installmentsData?.forEach((installment: InstallmentV2) => {
    if (
      installment?.is_hybrid_model_teaching_material &&
      installment?.status === 'OPEN'
    ) {
      openInstallments.push(installment);
    }

    if (
      installment?.is_hybrid_model_teaching_material &&
      installment?.status === 'PAID'
    ) {
      paidInstallments.push(installment);
    }
  });

  const sortedOpenInstallments = openInstallments.sort((a, b) => {
    return a.student.name.localeCompare(b.student.name);
  });

  const sortedPaidInstallments = paidInstallments.sort((a, b) => {
    return a.student.name.localeCompare(b.student.name);
  });

  const installments = [
    ...(sortedOpenInstallments ?? []),
    ...(sortedPaidInstallments ?? []),
  ];

  let shouldShowTeachingMaterial = true;
  const mostRecentDate = sortedPaidInstallments
    .map((i) => {
      if (i.paid_date) {
        return new Date(i.paid_date);
      }
      return new Date(0);
    })
    .reduce(
      (latest, current) => (current > latest ? current : latest),
      new Date(0)
    );

  const today = new Date();
  const fifteenDaysAgo = new Date(today.setDate(today.getDate() - 15));
  if (mostRecentDate < fifteenDaysAgo) {
    shouldShowTeachingMaterial = false;
  }

  let status = TeachingMaterialStatus.NO_HYBRID_MODEL_TEACHING_MATERIAL;
  if (installments.length > 0) {
    if (sortedOpenInstallments.length > 0) {
      status = TeachingMaterialStatus.AT_LEAST_ONE_OPEN;
    } else if (shouldShowTeachingMaterial) {
      status = TeachingMaterialStatus.ALL_PAID;
    }
  }
  return {
    installments,
    hasSingleInstallment: installments?.length === 1,
    status,
  };
};
