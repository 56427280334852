import React from 'react';
import type { NotificationProps } from './types';
import { View } from './view';
export type { NotificationProps } from './types';
export const Notification = ({
  variant,
  description,
  title
}: NotificationProps) => {
  return <View variant={variant} description={description} title={title} data-sentry-element="View" data-sentry-component="Notification" data-sentry-source-file="index.tsx" />;
};