import { cn } from '@rootTailwindUi/utils';
import { Carousel, CarouselContent, CarouselDots, CarouselItem, CarouselNext, CarouselPrevious } from '@srcTailwindUi/components/Carousel';
type Props = {
  banners: {
    component?: React.ReactNode;
    name: string;
  }[];
};
const BannerContainer = ({
  banners
}: Props) => {
  if (!banners.length) return null;
  return <Carousel orientation="horizontal" className={cn('m-auto w-full animate-banner', banners.length > 1 ? 'sm:w-[606px]' : 'sm:w-635px')} data-sentry-element="Carousel" data-sentry-component="BannerContainer" data-sentry-source-file="index.tsx">
      <CarouselContent className="ml-4 mr-6 pb-3 sm:-ml-4 sm:mr-0" data-sentry-element="CarouselContent" data-sentry-source-file="index.tsx">
        {banners?.map(banner => <CarouselItem key={banner.name} className="w-full pl-2 sm:basis-full sm:pl-4">
            {banner.component}
          </CarouselItem>)}
      </CarouselContent>
      <CarouselPrevious variant="default" className="hidden bg-transparent hover:bg-transparent sm:flex" data-sentry-element="CarouselPrevious" data-sentry-source-file="index.tsx" />
      <CarouselNext variant="default" className="hidden bg-transparent hover:bg-transparent sm:flex" data-sentry-element="CarouselNext" data-sentry-source-file="index.tsx" />
      <CarouselDots className="bg-transparent hover:bg-transparent sm:hidden" data-sentry-element="CarouselDots" data-sentry-source-file="index.tsx" />
    </Carousel>;
};
export { BannerContainer };